<template>
  <div class="animated fadeIn">
    <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
    <b-card v-else>
      <template v-slot:header>
        <h5>Akses Menu</h5>
      </template>
      <div
        class="search-wrapper d-flex align-items-center justify-content-between"
      >
        <b-form-group
          label="Filter"
          label-for="filterInput"
          class="form-inline form-group-wrapper mb-3"
        >
          <div class="d-flex search-input-wrapper">
            <b-form-input
              class="ml-2 mr-2"
              v-model="filterNamaMenu"
              name="filterTable"
              id="filterInput"
              type="search"
              placeholder="Nama Menu"
            ></b-form-input>
            <b-button
              class="mr-2"
              variant="pgiBtn"
              name="searchTableBtn"
              :disabled="isBusy"
              @click="onFilter"
            >
              <i class="fa fa-search"></i>
            </b-button>
            <b-button
              variant="pgiBtn"
              name="resetTableBtn"
              :disabled="isBusy"
              @click="onReset"
              >Reset</b-button
            >
          </div>
        </b-form-group>
      </div>
      <b-form @submit.prevent="openModalConfirm">
        <div class="custom-table" :style="`max-height: ${window.tableHeight}`">
          <table v-bind:style="{ opacity: busyOpacity }">
            <thead>
              <tr>
                <th v-for="table in fields" v-bind:key="table.key">
                  {{ table.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="custom-table-template" v-if="isBusy == true">
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading data...</strong>
                </div>
              </tr>
              <tr class="custom-table-template" v-if="dataMenu.length == 0">
                <td>
                  <div>Tidak ada menu</div>
                </td>
              </tr>
              <tr v-for="dataTable in dataMenu" v-bind:key="dataTable.id">
                <th class="custom-menu-name">
                  <div v-if="dataTable.description != null">
                    <p class="mb-0">
                      {{ dataTable.name }}
                      <i
                        class="fa fa-info tooltip-trigger-btn ml-1"
                        @click="openModalDetail(dataTable.id)"
                      ></i>
                    </p>
                  </div>
                  <p v-else class="mb-0">
                    {{ dataTable.name }}
                  </p>
                  <p class="mb-0 smallText text-muted bold-parent">
                    {{ dataTable.parent }}
                  </p>
                </th>
                <th v-if="dataTable.userSpecificMenu == true" colspan="12">
                  <div class="akses-menu-text">
                    Berdasarkan user pada Parameter > ADMINISTRASI.
                  </div>
                </th>
                <th
                  v-else
                  v-for="dataRole in dataTable.roles"
                  v-bind:key="dataRole.id"
                  class="custom-menu-role"
                >
                  <b-form-checkbox
                    v-model="dataRole.selected"
                  ></b-form-checkbox>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form-wrapper-btn submit-btn-wrapper mb-0">
          <b-button type="submit" name="updateAksesMenuBtn" variant="pgiBtn"
            >Update</b-button
          >
        </div>
      </b-form>
    </b-card>
    <ModalConfirm
      id="modal-confirm-edit"
      title="Confirm Update Akses Menu"
      :isSubmitting="isSubmitting"
      desc="Apakah anda yakin untuk mengubah akses menu ini?"
      @submit="onSubmit"
    />

    <b-modal
      id="modal-detail-informasi"
      title="Informasi"
      no-close-on-backdrop
      hide-footer
    >
      <div class="detail-data-wrapper mb-0">
        <h6 class="form-title detail-informasi-title">
          {{ singleDataInformasi.name }} - {{ singleDataInformasi.parent }}
        </h6>
        <span>{{ singleDataInformasi.description }}</span>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "master-akses-menu",
  data() {
    return {
      initStatus: 0,
      isBusy: true,
      busyOpacity: 0.55,
      isSubmitting: false,
      fields: [{ key: "name", label: "Nama Menu" }],
      dataMenu: [],
      allMenuData: [],
      payload: [],
      window: {
        // width: 0,
        // height: 0,
        tableHeight: 0,
      },
      filterNamaMenu: "",
      singleDataInformasi: {},

      role: [],
      menu: [],
    };
  },

  methods: {
    //----------------- Fungsi Inisialisasi -----------------


    init() {
      this.initStatus = 0;

      let getRole = this.$axios.get("api/admin/role").then((response) => {
        // this.dataMenu = response.data.menus
        //console.log("eksekusi ke 1")

        for (var role of response.data.data) {
          this.role.push(role);
        }

        for (let a = 0; a < this.role.length; a++) {
          let roleField = [];
          roleField.key = this.role[a].id + "-roles";
          roleField.label = this.role[a].name;
          this.fields.push(roleField);
        }

        this.$axios.get("api/role-menu/all").then((response) => {
          //console.log("eksekusi ke 2")

          //console.log(response.data.data.length)
          let datas = response.data.data;

          for (let data of datas) {
            this.menu.push(data);
          }

          // console.log(this.menu.length);
          for (let i = 0; i < this.menu.length; i++) {
            let menuData = [];
            menuData.id = this.menu[i].id;
            menuData.name = this.menu[i].name;
            menuData.roles = [];
            // console.log(this.menu[i].id)
            // console.log(this.menu[i])
            // console.log(this.role.length)
            // console.log(this.menu[i].role.length)
            for (let y = 0; y < this.role.length; y++) {
              //console.log("for role length");
              let dataCheckRoles = [];
              dataCheckRoles.id = this.role[y].id;
              //console.log(this.menu[i].role.length)
              if (this.menu[i].role.length != 0) {
                //console.log("this.menu[i].role.length != 0")
                for (let a = 0; a < this.menu[i].role.length; a++) {
                  let selected = this.menu[i].role.filter(
                    (item) => item == this.role[y].id
                  );
                  if (selected.length != 0) {
                    dataCheckRoles.selected = true;
                  } else {
                    dataCheckRoles.selected = false;
                  }
                }
              } else {
                dataCheckRoles.selected = false;
              }
              menuData.roles.push(dataCheckRoles);
            }

            this.dataMenu.push(menuData);
          }

          //console.log(this.fields)

          let msg = this.$helper.getFlash();
          this.$helper.toastSucc(this, msg);
        });
      });

      // let getMenu = this.$axios.get("api/role-menu/all").then(response => {
      //     console.log("eksekusi ke 2")
      //     console.log(response.data.data)

      //     console.log("Role",this.role)

      //     console.log(response.data.data.length)
      //     let datas =  response.data.data

      //     for(let data of datas){
      //         this.menu.push(data)
      //     }

      //     console.log(this.menu.length);
      //     for( let i = 0; i< this.menu.length ; i++){
      //         let menuData = []
      //         menuData.id = this.menu[i].id
      //         menuData.name = this.menu[i].name
      //         menuData.roles = []
      //         console.log(this.menu[i].id)
      //         console.log(this.menu[i])
      //         console.log(this.role.length)
      //         console.log(this.menu[i].role.length)
      //         for (let y=0; y< this.role.length; y++){
      //             console.log("for role length");
      //             let dataCheckRoles = []
      //             dataCheckRoles.id = this.role[y].id
      //             console.log(this.menu[i].role.length)
      //             if(this.menu[i].role.length != 0){
      //                 console.log("this.menu[i].role.length != 0")
      //                 for(let a = 0; a<this.menu[i].role.length; a++){
      //                     let selected = this.menu[i].role.filter(item => item == this.role[y].id)
      //                     if(selected.length != 0){
      //                         dataCheckRoles.selected = true
      //                     }else{
      //                         dataCheckRoles.selected = false
      //                     }
      //                 }
      //             }else{
      //                 dataCheckRoles.selected = false
      //             }
      //             menuData.roles.push(dataCheckRoles)
      //         }

      //         this.dataMenu.push(menuData)
      //     }
      //     console.log("menu",this.dataMenu)

      //     console.log(this.fields)

      //     let msg = this.$helper.getFlash()
      //     this.$helper.toastSucc(this, msg)
      // })

      Promise.all([getRole])
        .then(() => {
          this.initStatus = 1;
          this.toggleBusy(), (this.allMenuData = this.dataMenu);
        })
        .catch((error) => {
          console.log(error);
          this.initStatus = -1;
        });
    },

    //----------------- Fungsi Submit Data -----------------

    onSubmit() {
      //----------------- Append Data Akses Menu -----------------
      let payload = [];

      for (var x of this.dataMenu) {
        let a = {};
        a.menuIds = x.id;
        a.roleIds = [];

        for (let c = 0; c < x.roles.length; c++) {
          if (x.roles[c].selected == true) {
            a.roleIds.push(x.roles[c].id);
          }
        }

        payload.push(a);
      }

      let data = {
        data: payload,
      };

      //----------------- Submit Semua Data -----------------

      this.isSubmitting = true;

      this.$axios
        .post("api/role-menu", data)
        .then(() => {
          this.$helper.toastSucc(this, `Akses Menu telah berhasil diubah!`);
          this.$bvModal.hide("modal-confirm-edit");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

    //----------------- Fungsi Pindah Halaman dan Fungsi Lain -----------------

    toggleBusy() {
      if (this.isBusy == true) {
        this.isBusy = false;
        this.busyOpacity = 1;
      } else {
        this.isBusy = true;
        this.busyOpacity = 0.55;
      }
    },

    openModalConfirm() {
      this.$bvModal.show("modal-confirm-edit");
    },

    handleResize() {
      let windowHeight = window.innerHeight;
      let tableHeight = windowHeight - (55 + 46 + 50 + 82 + 75 + 48);
      this.window.tableHeight = tableHeight + "px";
    },

    onFilter() {
      if (this.filterNamaMenu != "") {
        let arrayCopy = [...this.allMenuData];
        let menuFilter = arrayCopy.filter((item) =>
          item.name.toLowerCase().includes(this.filterNamaMenu.toLowerCase())
        );
        this.dataMenu = menuFilter;
      } else {
        this.dataMenu = this.allMenuData;
      }
    },

    onReset() {
      this.filterNamaMenu = "";
      this.dataMenu = this.allMenuData;
    },

    openModalDetail(id) {
      let idx = this.dataMenu.findIndex((item) => item.id == id);
      this.singleDataInformasi = this.dataMenu[idx];
      this.$bvModal.show("modal-detail-informasi");
    },
  },
  created() {
    //this.getRole()
    this.init();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
};
</script>
